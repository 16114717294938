import { Box, VStack } from '@televet/kibble-ui/build/chakra';
import { Heading } from '@televet/kibble-ui/build/components/Heading';
import { Text } from '@televet/kibble-ui/build/components/Text';
import type { NextPage } from 'next';
import { useEffect } from 'react';
import { useAuth } from '../shared/providers/auth/authProvider';

const Logout: NextPage = () => {
  const { logout } = useAuth();
  useEffect(() => {
    logout();
  }, [logout]);

  return (
    <VStack spacing={4}>
      <Box width={'320px'}>
        <Heading size="md">404</Heading>
        <Text>This page cannot be found</Text>
      </Box>
    </VStack>
  );
};

export default Logout;
